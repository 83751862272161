import {init, addBreadcrumb, browserTracingIntegration} from '@sentry/vue'
import router from '../router'
import Vue from 'vue'

init({
    Vue,
    dsn: 'https://f2c0d02cc09e9763c106f94b8afb305e@sentry.it-team.cloud/133',
    tracePropagationTargets: ['localhost', 'localhost:8050'],
    integrations: [
        browserTracingIntegration({
            router
        })
    ],
    maxBreadcrumbs: 50,
    beforeBreadcrumb(breadcrumb) {
        if (breadcrumb.category === 'ui.click' && breadcrumb.message) {
            const regex = new RegExp('name="(.*?)"')
            const result = regex.exec(breadcrumb.message)

            if (result && result.length >= 2) {
                addBreadcrumb({
                    category: 'Custom',
                    message: `Clicked button ${result[1]}`,
                    level: 'info'
                })
            }

            return null
        } else if (breadcrumb.category === 'xhr') {
            if (breadcrumb.data && breadcrumb.data.url) {
                const url = breadcrumb.data.url

                const urlsToIgnore = []

                if (urlsToIgnore.includes(url)) {
                    return null
                }

            }
        }

        return breadcrumb
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0
})